import * as React from 'react';

import { Input, InputProps } from '../input';
import { PhoneMaskFormat, PhoneMaskType, getPhoneMask } from '@/shared/lib';
import { IMaskInput } from 'react-imask';
import { useBoolean, useConfig } from '@/shared/hooks';
import { CountryCode } from '@/shared/types';

export type InputPhoneProps = InputProps & {
  type?: PhoneMaskType;
  format?: PhoneMaskFormat;
};

type InputPhoneInnerProps = {
  onChange: (event: { currentTarget: { name: string; value: string } }) => void;
  name: string;
  maskType: 'landline' | 'mobile';
  maskFormat: 'international' | 'national';
};

export const InputPhoneInner = React.forwardRef<
  HTMLInputElement,
  InputPhoneInnerProps
>(
  (
    { maskFormat = 'national', maskType = 'mobile', name, onChange, ...props },
    ref,
  ) => {
    const isLazy = useBoolean(true);

    const config = useConfig();

    const mask = getPhoneMask({
      countryCode: config?.countryCode,
      format:
        config?.countryCode === CountryCode.IN ? 'international' : maskFormat,
      type: maskType,
    });

    const prepare = (value: string) => {
      return value
        .replace(/[\(\)]/, '')
        .replace(/^(\+60)|(\+62)|(\+91)|(\+94)/, '');
    };

    const handleAccept = (value: string) => {
      onChange({
        currentTarget: {
          name,
          value,
        },
      });
    };

    return (
      <IMaskInput
        {...props}
        eager={config?.countryCode === CountryCode.IN}
        inputRef={ref}
        mask={mask}
        lazy={isLazy.value}
        onAccept={handleAccept}
        onBlur={isLazy.on}
        onFocus={isLazy.off}
        prepare={prepare}
      />
    );
  },
);

InputPhoneInner.displayName = 'InputPhoneInner';

export const InputPhone = ({ format, type, ...props }: InputPhoneProps) => {
  return (
    <Input
      {...props}
      InputProps={{
        ...props.InputProps,
        inputComponent: InputPhoneInner as any,
      }}
      inputProps={{
        ...props.inputProps,
        maskFormat: format,
        maskType: type,
      }}
    />
  );
};

InputPhone.displayName = 'InputPhone';
