import { CountryCode } from '../types';

const masksByCountryCode: Record<
  CountryCode,
  Record<'landline' | 'mobile', Record<'international' | 'national', string>>
> = {
  ID: {
    landline: {
      international: '+62000 0000 00000',
      national: '\\0000 0000 00000',
    },
    mobile: {
      international: '+62800 0000 00000',
      national: '\\0800 0000 00000',
    },
  },
  LK: {
    landline: {
      international: '+94-00-0000000',
      national: '\\000-0000000',
    },
    mobile: {
      international: '+94-70-0000000',
      national: '\\070-0000000',
    },
  },
  MY: {
    landline: {
      international: '+6000-00000000',
      national: '\\0-00-00000000',
    },
    mobile: {
      international: '+6010-00000000',
      national: '\\0-10-00000000',
    },
  },
  VN: {
    landline: {
      international: '+8420 0000 0000',
      national: '020 0000 0000',
    },
    mobile: {
      international: '+8400 0000 0000',
      national: '\\000 0000 0000',
    },
  },
  IN: {
    landline: {
      international: '+91 0000 000000',
      national: '0 0000 000000',
    },
    mobile: {
      international: '+91 0000 000000',
      national: '0 0000 000000',
    },
  },
  BD: {
    landline: {
      international: '+880 0000 000 000',
      national: '0 0000 000 000',
    },
    mobile: {
      international: '+880 0000 000 000',
      national: '0 0000 000 000',
    },
  },
  MX: {
    landline: {
      international: '+52 00 0000 0000',
      national: '0 0000 000 000',
    },
    mobile: {
      international: '+52 00 0000 0000',
      national: '0 0000 000 000',
    },
  },
};

export type PhoneMaskFormat = 'national' | 'international';
export type PhoneMaskType = 'landline' | 'mobile';

export type GetPhoneMaskParams = {
  countryCode?: CountryCode;
  format?: PhoneMaskFormat;
  type?: PhoneMaskType;
};

export const getPhoneMask = ({
  countryCode = CountryCode.LK,
  type = 'mobile',
  format = 'national',
}: GetPhoneMaskParams = {}) => {
  return masksByCountryCode[countryCode][type][format];
};
