import {
  Box,
  ButtonBase,
  IconButton,
  Slide,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { IconClose } from './icons';
import { useBoolean } from '../hooks';
import { useWindowScroll } from '@uidotdev/usehooks';
import { useEffect } from 'react';
import { scrollToCalculatorNode } from '@/utils';
import { useCalculatorStore } from '@/stores/calculator/calculator-store-provider';

export type PopupBannerProps = {
  imageUrl: string;
  imageDesktopUrl?: string;
};

export const PopupBanner = ({
  imageUrl,
  imageDesktopUrl,
}: PopupBannerProps) => {
  const isManuallyClosed = useBoolean(false);
  const isShown = useBoolean(false);
  const { on } = useCalculatorStore((state) => state);

  const [scroll] = useWindowScroll();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const blockHeight = 200;
  const scrollY = scroll.y || 0;
  const src = isDesktop && imageDesktopUrl ? imageDesktopUrl : imageUrl;

  useEffect(() => {
    if (scrollY > blockHeight) {
      isShown.on();
    } else {
      isShown.off();
    }
  }, [blockHeight, scrollY, isShown]);

  const handleClose = () => {
    isManuallyClosed.on();
  };

  const scrollToCalculator = () => {
    scrollToCalculatorNode();
    on();
  };

  return (
    <Slide
      direction="up"
      in={!isManuallyClosed.value && isShown.value}
      mountOnEnter={true}
      unmountOnExit={true}
    >
      <Box bottom={0} left={0} right={0} position="fixed" zIndex={1000}>
        <Box maxWidth="lg" mx="auto" position="relative" width="fit-content">
          <ButtonBase onClick={scrollToCalculator}>
            <Box
              component="img"
              display="block"
              src={src}
              sx={{
                cursor: 'pointer',
                objectFit: 'contain',
              }}
              width="100%"
            />
          </ButtonBase>
          <IconButton
            sx={{
              color: 'white',
              position: 'absolute',
              right: 8,
              top: 8,
            }}
            onClick={handleClose}
          >
            <IconClose />
          </IconButton>
        </Box>
      </Box>
    </Slide>
  );
};
