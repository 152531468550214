import { Config } from '../types';

const config: Config = {
  apiUrl: process.env.API_URL,
  currency: 'Mxn',
  defaultLocale: 'es-MX',
  locales: ['es-MX'],
  cdnUrl: process.env.CDN_PATH,
  countryCode: process.env.COUNTRY_CODE,
  faviconUrl: `${process.env.CDN_PATH}/favicons/favicon.ico`,
  logoUrl: `${process.env.CDN_PATH}/logos/full.svg`,
  logoUrlLight: `${process.env.CDN_PATH}/logos/full-light.svg`,
  logoUrlShort: `${process.env.CDN_PATH}/logos/short.svg`,
  openGraphLogoUrl: `${process.env.CDN_PATH}/logos/openGraphLogo.png`,
  title: 'Donpeso',
  googleTagManagerId: process.env.GOOGLE_TAG_MANAGER_ID,
  phonePrefixInternational: '+52',
  phonePrefixNational: '00',
  externalLinks: {
    whatsApp: '#',
    viber: '#',
    payEmail: '#',
    supportEmail: '#',
  },
  domain: 'donpeso.mx',
  bankAccountHolderName: '',
  chatbotClientKey: '',
};

export default config;
