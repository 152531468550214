import React from 'react';
import { useTranslations } from 'next-intl';
import { useConfig, useCountryCode } from '@/shared/hooks';
import { Box, Divider, List, Stack, Typography, useTheme } from '@mui/material';
import {
  IconCreditCard,
  IconDialpadAlt,
  IconInfoCircle,
  IconReceipt,
  IconUserCircle,
} from '@/shared/ui';
import HelpIcon from '@mui/icons-material/Help';
import { ListItem } from './list-item';
import { CountryCode } from '@/shared/types';

interface RepaymentRequisitesProps {
  accountNumber?: string;
  bankAccountHolderName: string;
  branchName?: string;
  onHelpIconClick: () => void;
}

export const RepaymentRequisites = ({
  accountNumber,
  bankAccountHolderName,
  branchName,
  onHelpIconClick,
}: RepaymentRequisitesProps) => {
  const t = useTranslations();

  const theme = useTheme();
  const config = useConfig();
  const countryCode = useCountryCode();

  const isTambadanaLanding = countryCode === CountryCode.MY;

  const currentLoanAgreementNumber = isTambadanaLanding
    ? 'repayment.tambadanaRequisitesLoanAgreementNumberValue'
    : 'repayment.requisitesLoanAgreementNumberValue';

  const currentRequisitesLoanAgreementNumberLabelText = isTambadanaLanding
    ? 'repayment.requisitesLoanAgreementNumberLabel_v2'
    : 'repayment.requisitesLoanAgreementNumberLabel';

  return (
    <Box>
      <List sx={{ p: 0 }}>
        <ListItem
          primaryText={t('repayment.requisitesBankAccountHolderName')}
          secondaryText={bankAccountHolderName}
          IconComponent={
            <IconUserCircle htmlColor={theme.palette.neutral[50]} />
          }
        />
        <Divider />

        {branchName ? (
          <React.Fragment>
            <ListItem
              primaryText={t('repayment.requisitesBankAccountBranchName')}
              secondaryText={branchName}
              IconComponent={
                <IconCreditCard htmlColor={theme.palette.neutral[50]} />
              }
            />

            <Divider />
          </React.Fragment>
        ) : null}

        {accountNumber ? (
          <React.Fragment>
            <ListItem
              primaryText={t(
                config?.countryCode === 'MY'
                  ? 'repayment.requisitesBankBusinessRegistrationNumber'
                  : 'repayment.requisitesBankAccountNumber',
              )}
              secondaryText={accountNumber}
              IconComponent={
                <IconDialpadAlt htmlColor={theme.palette.neutral[50]} />
              }
            />

            <Divider />
          </React.Fragment>
        ) : null}

        <ListItem
          primaryText={t(currentRequisitesLoanAgreementNumberLabelText)}
          secondaryText={
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              component="span"
            >
              <Typography component="span">
                {t(currentLoanAgreementNumber)}
              </Typography>

              {!isTambadanaLanding ? (
                <IconInfoCircle
                  onClick={onHelpIconClick}
                  sx={{
                    p: 0,
                    cursor: 'pointer',

                    '&:hover': {
                      color: 'neutral.20',
                    },
                  }}
                >
                  <HelpIcon />
                </IconInfoCircle>
              ) : null}
            </Stack>
          }
          IconComponent={<IconReceipt htmlColor={theme.palette.neutral[50]} />}
        />
      </List>
    </Box>
  );
};
