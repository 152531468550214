import { createStore } from 'zustand/vanilla';

type PopupBannerState = {
  alreadyShowed: boolean;
};

type PopupBannerActions = {
  setAlreadyShowed: (isShowed: boolean) => void;
};

export type PopupBannerStore = PopupBannerState & PopupBannerActions;

export const initPopupBannerStore = (): PopupBannerState => {
  return {
    alreadyShowed: false,
  };
};

export const defaultInitState: PopupBannerState = {
  alreadyShowed: false,
};

export const createPopupBannerStore = (
  initState: PopupBannerState = defaultInitState,
) => {
  return createStore<PopupBannerStore>()((set) => ({
    ...initState,
    setAlreadyShowed: (isShowed) => set({ alreadyShowed: isShowed }),
  }));
};
