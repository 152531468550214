import { Box, useMediaQuery, useTheme } from '@mui/material';
import Image from 'next/image';

import { IImage } from '../types';
import { ResponsiveTypography } from './responsive-typography';
import { SectionContainer } from './section-container';

export type PersonQuoteProps = {
  image: IImage;
  name?: string;
  position?: string;
  title: string;
};

export const PersonQuote = ({
  image,
  name,
  position,
  title,
}: PersonQuoteProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const captionEl = (
    <>
      {name ? (
        <ResponsiveTypography
          color="neutral.50"
          variantMap={{
            xs: 'headline7',
            md: 'headline6',
          }}
        >
          {name}
        </ResponsiveTypography>
      ) : null}
      {position ? (
        <ResponsiveTypography
          color="neutral.50"
          variantMap={{
            xs: 'text6',
            md: 'text5',
          }}
        >
          {position}
        </ResponsiveTypography>
      ) : null}
    </>
  );

  return (
    <SectionContainer disableGutters={isMobile}>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        <Box maxWidth={506} px={{ xs: 2, sm: 0 }} mr={{ xs: 0, md: '-100px' }}>
          <ResponsiveTypography
            variantMap={{
              xs: 'headline5',
              md: 'headline3',
            }}
            color="neutral.20"
            mb={{ xs: 3, md: 5 }}
          >
            {title}
          </ResponsiveTypography>
          <Box display={{ xs: 'none', md: 'block' }}>{captionEl}</Box>
        </Box>
        <Image
          src={image.data.attributes.url}
          width={image.data.attributes.width}
          height={image.data.attributes.height}
          alt={image.data.attributes.alternativeText as string}
          style={{
            width: '100%',
            height: 'auto',
            maxWidth: 800,
          }}
        />
        <Box ml="22%" display={{ md: 'none' }}>
          {captionEl}
        </Box>
      </Box>
    </SectionContainer>
  );
};
