import {
  LANDING_AB_TESTS_STORAGE_KEY,
  LANDING_PHONE_NUMBER_STORAGE_KEY,
  LANDING_REFERRER_STORAGE_KEY,
  LANDING_URL_STORAGE_KEY,
} from '../constants';

export const encodeLandingInfo = () => {
  const abTests = localStorage.getItem(LANDING_AB_TESTS_STORAGE_KEY);
  const phoneNumber = sessionStorage.getItem(LANDING_PHONE_NUMBER_STORAGE_KEY);
  const referrer = localStorage.getItem(LANDING_REFERRER_STORAGE_KEY);
  const url = localStorage.getItem(LANDING_URL_STORAGE_KEY);

  const landingInfo = {
    'landing.abTests': abTests,
    'landing.phoneNumber': phoneNumber,
    'landing.referrer': referrer,
    'landing.url': url,
  };

  return btoa(JSON.stringify(landingInfo));
};
