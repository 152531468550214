import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconArrow = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3431 4.92888L19.7071 11.2928C20.0976 11.6834 20.0976 12.3165 19.7071 12.7071L13.3431 19.071C12.9526 19.4615 12.3195 19.4615 11.9289 19.071C11.5384 18.6805 11.5384 18.0473 11.9289 17.6568L16.5858 12.9999L5 12.9999C4.44771 12.9999 4 12.5522 4 11.9999C4 11.4477 4.44771 10.9999 5 10.9999L16.5858 10.9999L11.9289 6.34309C11.5384 5.95257 11.5384 5.3194 11.9289 4.92888C12.3195 4.53836 12.9526 4.53836 13.3431 4.92888Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
