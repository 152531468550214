import { createTheme, ThemeOptions } from '@mui/material/styles';

import { core, CustomColor, gray, error } from './core';

const primary: CustomColor = {
  0: '#000000',
  10: '#23163d',
  20: '#452c7a',
  30: '#6842b6',
  40: '#8A58F3',
  50: '#9e74f5',
  60: '#b190f7',
  70: '#c5acf9',
  80: '#d8c7fb',
  90: '#ece3fd',
  95: '#E8DEFD',
  99: '#fdfcff',
  100: '#ffffff',
};

const secondary: CustomColor = {
  0: '#000000',
  10: '#2c2340',
  20: '#584580',
  30: '#8368bf',
  40: '#AF8AFF',
  50: '#bc9eff',
  60: '#cab1ff',
  70: '#d7c5ff',
  80: '#e4d8ff',
  90: '#f2ecff',
  95: '#f9f6ff',
  99: '#fefdff',
  100: '#ffffff',
};

const theme: ThemeOptions = {
  palette: {
    primary: {
      ...primary,
      main: primary[40],
    },
    secondary: {
      ...secondary,
      main: '#AF8AFF',
    },
    error: {
      ...error,
      contrastText: error[100],
      dark: error[30],
      light: error[50],
      main: error[40],
    },
    common: {
      background: gray[100],
      error: error[40],
      errorContainer: error[90],
      onBackground: gray[10],
      onError: gray[100],
      onErrorContainer: gray[10],
      onPrimary: primary[100],
      onPrimaryContainer: gray[10],
      onSecondary: secondary[100],
      onSecondaryContainer: gray[10],
      onSurface: gray[10],
      onSurfaceVariant: gray[30],
      outline: gray[50],
      primary: primary[40],
      primaryContainer: primary[90],
      secondary: secondary[40],
      secondaryContainer: secondary[90],
      surface: gray[100],
      surface1: gray[99],
      surface2: gray[95],
      surface3:
        'linear-gradient(0deg, rgba(0, 93, 184, 0.10) 0%, rgba(0, 93, 184, 0.10) 100%), #ffffff',
      surface4:
        'linear-gradient(0deg, rgba(0, 93, 184, 0.12) 0%, rgba(0, 93, 184, 0.12) 100%), #fdfbff',
      surface5:
        'linear-gradient(0deg, rgba(0, 93, 184, 0.15) 0%, rgba(0, 93, 184, 0.15) 100%), #fdfbff',
      surfaceVariant: gray[90],
      inverseSurface: gray[20],
    },
  },
};

export const donpeso = createTheme(core, theme);
