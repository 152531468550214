import { ApiInstance } from './instance';
import { TSEOComponentData } from '@/shared/types';

export interface IRuleContent {
  title: string;
  content: string;
  seo?: TSEOComponentData;
}

export class RulesContentApi extends ApiInstance {
  constructor(locale?: string) {
    super(locale);
  }

  async getPrivacyPolicy(): Promise<IRuleContent> {
    const query = {
      populate: [
        'seo',
        'seo.metaSocial',
        'seo.metaSocial.image',
        'seo.metaImage',
      ],
    };

    return this._SendWithQuery('/privacy-policy', query).then(
      (res: any) => res?.attributes || {},
    );
  }

  async getTermsAndConditions(): Promise<IRuleContent> {
    return this._Send('/terms-and-conditions').then(
      (res: any) => res?.attributes || {},
    );
  }

  async getCodeOfConduct(): Promise<IRuleContent> {
    return this._Send('/code-of-conduct').then(
      (res: any) => res?.attributes || {},
    );
  }

  async getRatesTerms(): Promise<IRuleContent> {
    return this._Send('/rates-terms').then((res: any) => res?.attributes || {});
  }

  async getFairPracticesCode(): Promise<IRuleContent> {
    return this._Send('/fair-practices-code').then(
      (res: any) => res?.attributes || {},
    );
  }

  async getExperianTermsAndConditions(): Promise<IRuleContent> {
    return this._Send('/experian-terms-and-conditions').then(
      (res: any) => res?.attributes || {},
    );
  }

  async getRefundPolicy(): Promise<IRuleContent> {
    return this._Send('/refund-policy').then(
      (res: any) => res?.attributes || {},
    );
  }
}
