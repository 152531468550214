import { ApiInstance } from './instance';
import { IImage, TPageData } from '@/shared/types';
import {
  FirstLoanCalculator,
  THomeBlockFirstLoanCalculator,
} from '@/shared/api/homepage-api';

export enum ComponentType {
  Paragraph = 'blog.article-paragraph',
  Image = 'blog.article-image',
  Button = 'shared.nav-link',
  Calculator = 'blog.first-loan-calcularor',
  BlogFeaturesImageCalculatorSection = 'blog.features-image-calculator-section',
  BlogIntroductionSection = 'blog.introduction-section',
  BlogBodyContentSection = 'blog.body-content-section',
  BlogTableOfContentsSection = 'blog.table-of-contents-section',
  BlogConclusionsSection = 'blog.conclusions-section',
  BlogShortCalculatorSection = 'blog.short-calculator-section',
}
export type ArticleParagraph = {
  id: number;
  text: string;
  __component: ComponentType.Paragraph;
};

export type ArticleImage = {
  id: number;
  image: IImage;
  __component: ComponentType.Image;
};

export type ArticleButton = {
  id: number;
  text: string;
  to: string;
  __component: ComponentType.Button;
};

export type ArticleCalculator = {
  display: boolean;
  id: number;
  __component: ComponentType.Calculator;
};

export type TBlogFeaturesImageCalculatorSection = {
  id: number;
  imageDesktop: IImage;
  imageMobile: IImage;
  calculator: FirstLoanCalculator;
  __component: ComponentType.BlogFeaturesImageCalculatorSection;
};

export type TBlogIntroductionSection = {
  id: number;
  introduction: string;
  __component: ComponentType.BlogIntroductionSection;
};

export type TBlogTableOfContentsSection = {
  id: number;
  items: { text: string }[];
  __component: ComponentType.BlogTableOfContentsSection;
};

export type TBlogBodyContentSection = {
  id: number;
  subTitle?: string;
  text?: string;
  buttonText?: string;
  __component: ComponentType.BlogBodyContentSection;
};

export type TBlogConclusionsSection = {
  id: number;
  conclusions: string;
  __component: ComponentType.BlogConclusionsSection;
};

export type TBlogShortCalculatorSection = {
  id: number;
  image: IImage;
  calculator: FirstLoanCalculator;
  __component: ComponentType.BlogShortCalculatorSection;
};

export type ArticlePart =
  | ArticleParagraph
  | ArticleImage
  | ArticleButton
  | ArticleCalculator;

export type ContentData =
  | TBlogFeaturesImageCalculatorSection
  | TBlogIntroductionSection
  | TBlogTableOfContentsSection
  | TBlogBodyContentSection
  | TBlogConclusionsSection
  | TBlogShortCalculatorSection;

export type BlogArticle = {
  id: number;
  attributes: {
    createdAt: string;
    publishedAt: string;
    updatedAt: string;
    article: ArticlePart[];
    content: ContentData[];
    articleImageTitle: IImage;
    articleTitle: string;
    locale: string;
    localizations: { data: BlogArticle[] };
  };
};

export type BlogPageDataAttributes = {
  h1: string;
  extendedArticlesListTitle: string;
  moreNewsTitle: string;
  calculator: THomeBlockFirstLoanCalculator;
};

export type BlogPageData = TPageData<BlogPageDataAttributes>;

export class BlogApi extends ApiInstance {
  constructor(locale?: string) {
    super(locale);
  }

  getArticles() {
    const query = {
      populate: '*',
      sort: { publishedAt: 'desc' },
    };

    return this._SendWithQuery<BlogArticle[]>('/blogs', query);
  }

  getArticle(id: string) {
    const query = {
      populate: {
        article: {
          populate: ['image', 'localizations'],
        },
        content: {
          populate: [
            'image',
            'items',
            'imageDesktop',
            'imageMobile',
            'calculator',
            'calculator.imageDesktop',
            'calculator.imageMobile',
            'calculator.bannerMobile',
            'calculator.security',
          ],
        },
      },
    };

    return this._SendWithQuery<BlogArticle>(`/blogs/${id}`, query);
  }

  getContent(): Promise<any> {
    const query = {
      populate: [
        'calculator',
        'calculator.title',
        'calculator.subtitle',
        'calculator.calculator',
        'calculator.calculatorSelect',
        'calculator.imageDesktop',
        'calculator.imageMobile',
        'calculatorSelect',
        'seo',
        'seo.metaSocial',
        'seo.metaSocial.image',
        'seo.metaImage',
      ],
    };

    return this._SendWithQuery<BlogPageData>('/blog-page', query);
  }
}
