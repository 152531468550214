import { PropsWithChildren, useEffect, useRef } from 'react';

let firstClientX: number, clientX: number;

const preventTouch = (e: TouchEvent) => {
  const minValue = 5; // threshold

  clientX = e.touches[0].clientX - firstClientX;

  // Вертикальная прокрутка не работает, если начать проводить пальцем по горизонтали
  if (Math.abs(clientX) > minValue) {
    e.preventDefault();
    e.returnValue = false;

    return false;
  }
};

const touchStart = (e: TouchEvent) => {
  firstClientX = e.touches[0].clientX;
};

type PreventCloseGesture = PropsWithChildren;

/**
 * Предотвращает закрытие свайпом для браузеров открытых через мобильное приложение Facebook/Tiktok и тд.
 * https://stackoverflow.com/questions/76628528/how-to-programmatically-disable-or-avoid-closing-gesture-on-webpage-that-is-runn
 */
export const PreventCloseGesture = ({ children }: PreventCloseGesture) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    containerRef.current.addEventListener('touchstart', touchStart);
    containerRef.current.addEventListener('touchmove', preventTouch, {
      passive: false,
    });

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('touchstart', touchStart);
        containerRef.current.removeEventListener('touchmove', preventTouch, {
          passive: false,
        } as EventListenerOptions);
      }
    };
  }, [containerRef]);

  return <div ref={containerRef}>{children}</div>;
};
