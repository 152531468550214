import { createStore } from 'zustand/vanilla';

type CalculatorState = {
  highlightPhoneNumber: boolean;
  isFormSubmitted: boolean;
  isFocused: boolean;
};

type CalculatorActions = {
  on: () => void;
  off: () => void;
  setFocused: (focused: boolean) => void;
  setFormSubmitted: () => void;
};

export type CalculatorStore = CalculatorState & CalculatorActions;

export const initCalculatorStore = (): CalculatorState => {
  return {
    highlightPhoneNumber: false,
    isFocused: false,
    isFormSubmitted: false,
  };
};

export const defaultInitState: CalculatorState = {
  highlightPhoneNumber: false,
  isFormSubmitted: false,
  isFocused: false,
};

export const createCalculatorStore = (
  initState: CalculatorState = defaultInitState,
) => {
  return createStore<CalculatorStore>()((set) => ({
    ...initState,
    on: () => set({ highlightPhoneNumber: true }),
    off: () => set({ highlightPhoneNumber: false }),
    setFocused: (focused) => set({ isFocused: focused }),
    setFormSubmitted: () => set({ isFormSubmitted: true }),
  }));
};
