import { useConfig } from '@/shared/hooks/use-config';
import { useCommonApi } from '@/shared/contexts';

export const numberWithSeparator = (x: number, separator: string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const useFormatters = () => {
  const appConfig = useConfig();
  const commonApiContext = useCommonApi();

  const thousandSeparatorFromApiData =
    commonApiContext?.layoutSettings?.attributes?.internationalization
      ?.thousandSeparator;

  const formatNumberWithCommonApiContextSeparator = (x: number): string => {
    return numberWithSeparator(x, thousandSeparatorFromApiData || ',');
  };

  const formatCurrency = (value?: number): string => {
    if (typeof value !== 'number' && !value) {
      return '';
    }

    const currencyChar =
      commonApiContext?.layoutSettings?.attributes?.internationalization
        ?.currencyChar || appConfig?.currency;

    return `${currencyChar} ${numberWithSeparator(
      value,
      thousandSeparatorFromApiData || ',',
    )}`;
  };

  const formatPhoneNumber = (phoneNumber: string): string => {
    const rawValue = phoneNumber.replace(/[-_ ]/g, '');
    return rawValue.replace(/^0/, appConfig?.phonePrefixInternational || '');
  };

  return {
    formatCurrency,
    formatPhoneNumber,
    formatNumberWithCommonApiContextSeparator,
  };
};
