import { PropsWithChildren } from 'react';
import { Button, Grid, SxProps, Theme } from '@mui/material';
import { ResponsiveTypography, SectionContainer } from '@/shared/ui';
import { handleActionButtonClick } from '@/utils';

type PageContainerProps = PropsWithChildren<{
  title?: string;
  buttonText?: string;
  sx?: SxProps<Theme>;
  titleSx?: SxProps<Theme>;
  buttonSx?: SxProps<Theme>;
}>;

export const PageContainer = ({
  title,
  buttonText,
  sx,
  titleSx,
  buttonSx,
  children,
}: PageContainerProps) => {
  return (
    <SectionContainer sx={sx}>
      <Grid container rowSpacing={{ xs: 4, md: 5 }}>
        {title && (
          <Grid item xs={12} textAlign="center" sx={titleSx}>
            <ResponsiveTypography
              variantMap={{ xs: 'headline5', md: 'headline3' }}
            >
              {title}
            </ResponsiveTypography>
          </Grid>
        )}

        {children}

        {buttonText && (
          <Grid item xs={12} textAlign="center" sx={buttonSx}>
            <Button
              onClick={handleActionButtonClick}
              sx={{ width: { xs: '100%', md: 362 } }}
            >
              {buttonText}
            </Button>
          </Grid>
        )}
      </Grid>
    </SectionContainer>
  );
};
