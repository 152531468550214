'use client';

import { type ReactNode, createContext, useRef, useContext } from 'react';
import { type StoreApi, useStore } from 'zustand';
import {
  createPopupBannerStore,
  initPopupBannerStore,
  PopupBannerStore,
} from '@/stores/popup-banner/popup-banner-store';

export const PopupBannerStoreContext =
  createContext<StoreApi<PopupBannerStore> | null>(null);

export interface PopupBannerStoreProviderProps {
  children: ReactNode;
}

export const PopupBannerStoreProvider = ({
  children,
}: PopupBannerStoreProviderProps) => {
  const storeRef = useRef<StoreApi<PopupBannerStore>>();
  if (!storeRef.current) {
    storeRef.current = createPopupBannerStore(initPopupBannerStore());
  }

  return (
    <PopupBannerStoreContext.Provider value={storeRef.current}>
      {children}
    </PopupBannerStoreContext.Provider>
  );
};

export const usePopupBannerStore = <T,>(
  selector: (store: PopupBannerStore) => T,
): T => {
  const calculatorStoreContext = useContext(PopupBannerStoreContext);

  if (!calculatorStoreContext) {
    throw new Error(
      `usePopupBannerStore must be use within PopupBannerStoreProvider`,
    );
  }

  return useStore(calculatorStoreContext, selector);
};
