import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { LinearProgress, Stack } from '@mui/material';

export const RouteChangeProgress = () => {
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router]);

  if (loading) {
    return (
      <Stack
        width={1}
        sx={{ position: 'fixed' }}
        zIndex={(theme) => theme.zIndex.snackbar}
      >
        <LinearProgress
          sx={{
            height: 2,
          }}
        />
      </Stack>
    );
  }

  return null;
};
