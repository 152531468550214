import { Config } from '../types';

const config: Config = {
  apiUrl: process.env.API_URL,
  currency: 'Rs',
  defaultLocale: 'si-LK',
  locales: ['si-LK', 'en'],
  cdnUrl: process.env.CDN_PATH,
  countryCode: process.env.COUNTRY_CODE,
  faviconUrl: `${process.env.CDN_PATH}/favicons/favicon.ico`,
  logoUrl: `${process.env.CDN_PATH}/logos/full.svg`,
  logoUrlLight: `${process.env.CDN_PATH}/logos/full-light.svg`,
  logoUrlShort: `${process.env.CDN_PATH}/logos/short.svg`,
  openGraphLogoUrl: `${process.env.CDN_PATH}/logos/openGraphLogo.png`,
  title: 'Ceyloan',
  googleTagManagerId: process.env.GOOGLE_TAG_MANAGER_ID,
  phonePrefixInternational: '+94',
  phonePrefixNational: '0',
  externalLinks: {
    whatsApp: 'https://api.whatsapp.com/send?phone=94742499595',
    payEmail: 'pay@ceyloan.lk',
    supportEmail: 'cs@ceyloan.lk',
  },
  domain: 'ceyloan.lk',
  bankAccountHolderName: 'Ceylontech Lanka Pvt Ltd',
  chatbotClientKey: '9bf23704-e81b-4127-a01c-9abaee161488',
};

export default config;
