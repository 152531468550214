import { HttpMethod } from '@/shared/api/types';

export type SendVisitQueryParams = {
  referrer: string;
  user_agent: string;
  landing_url: string;
  first_visit_id: string;
  extra: { landing_ab_tests: object };
};

export const sendVisitRequest = (
  apiUrl: string | undefined,
  params: SendVisitQueryParams,
) => {
  const url = `${apiUrl}/clients-events/send_visit`;

  const body = JSON.stringify({
    ...params,
  });

  return fetch(url, {
    method: HttpMethod.POST,
    body,
  });
};
