import { countryCode } from '@/shared/config';
import { CountryCode } from '@/shared/types';

import ceyloan from './settings/ceyloan';
import tambadana from './settings/tambadana';
import myvay from './settings/myvay';
import trustPaisa from './settings/trustpaisa';
import ammana from './settings/ammana';
import dhardesh from './settings/dhardesh';
import donpeso from './settings/donpeso';

import { Config } from './types';

const configByCountryCode: Record<CountryCode, Config> = {
  [CountryCode.LK]: ceyloan,
  [CountryCode.MY]: tambadana,
  [CountryCode.VN]: myvay,
  [CountryCode.IN]: trustPaisa,
  [CountryCode.ID]: ammana,
  [CountryCode.BD]: dhardesh,
  [CountryCode.MX]: donpeso,
};

export const config: Config = configByCountryCode[countryCode];
