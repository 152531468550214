import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconCheck = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6783 7.02802C19.0841 7.40262 19.1094 8.03528 18.7348 8.4411L10.6154 17.2371L5.2652 11.4411C4.8906 11.0353 4.9159 10.4026 5.32172 10.028C5.72755 9.65342 6.3602 9.67872 6.73481 10.0845L10.6154 14.2885L17.2652 7.08454C17.6398 6.67872 18.2725 6.65342 18.6783 7.02802Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
