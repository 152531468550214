export const AB_TESTING_REDIRECT_STORAGE_KEY = 'AB_ON362';
export const ACCESS_TOKEN_STORAGE_KEY = 'accessToken';
export const COUNTRY_CODE_STORAGE_KEY = 'countryCode';
export const LANDING_AB_TESTS_STORAGE_KEY = 'landing.abTests';
export const LANDING_LOCALE_STORAGE_KEY = 'landing.locale';
export const LANDING_PHONE_NUMBER_STORAGE_KEY = 'landing.phoneNumber';
export const LANDING_REFERRER_STORAGE_KEY = 'landing.referrer';
export const LANDING_URL_STORAGE_KEY = 'landing.url';
export const LOCAL_STORAGE_KEY = 'utm';
export const REFRESH_TOKEN_STORAGE_KEY = 'refreshToken';
export const USER_ACTIONS_STORAGE_KEY = 'landing_page_actions';
export const FIRST_VISIT_ID_STORAGE_KEY = 'first_visit_id';
export const CURRENT_VISIT_ID_STORAGE_KEY = 'current_visit_id';
