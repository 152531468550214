import { useRouter } from 'next/router';
import TagManager from 'react-gtm-module';
import { LOCAL_STORAGE_KEY } from '@/shared/constants';

export type UTM = Record<string, string>;

export const useUtmParams = (): UTM | undefined => {
  const { query } = useRouter();

  if (query === null || typeof query !== 'object') {
    return undefined;
  }

  const keys = Object.keys(query);

  if (!keys.length) {
    return undefined;
  }

  return keys.reduce((utm, key) => {
    return key.startsWith('utm_') ? { ...utm, [key]: query[key] } : utm;
  }, {});
};

export const setUtm = (utm: UTM) => {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(utm));
  } catch (e) {
    console.error(e);
  }
};

const init = ({ id }: { id?: string }) => {
  if (id) {
    TagManager.initialize({
      gtmId: id,
    });
  }
};

export const GoogleTagManager = {
  init,
  setUtm,
};

export const isByCreditLine = (searchParams: URLSearchParams) => {
  return searchParams.get('utm_campaign')?.endsWith('_creditline_test');
};
