import { Box, Button, Container, Grid, Link, Stack } from '@mui/material';
import NextLink from 'next/link';
import { NavLink } from '@/shared/lib/navigations';
import { Logo } from '@/shared/ui/logo';
import { LocaleSwitcher } from '@/shared/ui/locale-switcher';
import { HeaderData, ILayoutSettings } from '@/shared/api/common-api';
import { ResponsiveTypography } from '@/shared/ui';
import { useBoolean } from '@/shared/hooks';
import { redirectToAccount } from '@/utils';

import { SwipeableNav } from './ui/swipeable-nav';
import { BurgerMenuBtn } from './ui';

export type HeaderProps = {
  links: NavLink[];
} & Pick<
  ILayoutSettings['attributes'],
  'socialNetworks' | 'supportPhoneNumber' | 'supportEmail'
> &
  Partial<
    Pick<
      HeaderData,
      'accountBtnText' | 'menuBtnText' | 'menuCloseText' | 'showAccountBtn'
    >
  >;

export const Header = ({
  accountBtnText,
  menuCloseText,
  menuBtnText,
  links = [],
  supportPhoneNumber,
  supportEmail,
  socialNetworks,
  showAccountBtn,
}: HeaderProps) => {
  const showDrawer = useBoolean();

  const logo = (
    <Box
      width={{
        xs: 90,
        md: 165,
      }}
    >
      <Link href="/" component={NextLink}>
        <Logo variant={showDrawer.value ? 'light' : 'primary'} />
      </Link>
    </Box>
  );

  return (
    <Box
      className="mui-fixed"
      component="header"
      position="fixed"
      zIndex={(theme) =>
        showDrawer.value ? theme.zIndex.drawer + 1 : theme.zIndex.drawer - 1
      }
      bgcolor={showDrawer.value ? 'inherit' : 'neutral.100'}
      sx={{
        width: '100%',
        borderBottomWidth: { xs: 1, md: 0 },
        borderBottomStyle: 'solid',
        borderBottomColor: showDrawer.value ? 'primary.50' : 'neutral.90',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          py: 2,
        }}
      >
        <Grid
          container
          columnSpacing={1}
          justifyContent="space-between"
          flexWrap="nowrap"
        >
          <Grid item xs="auto">
            {logo}
          </Grid>

          <Grid item xs="auto">
            <Stack direction="row" alignItems="center" spacing={1}>
              <LocaleSwitcher isActive={showDrawer.value} />

              {showAccountBtn ? (
                <Button
                  variant="outlined"
                  color={showDrawer.value ? 'secondary' : 'primary'}
                  size="small"
                  onClick={() => {
                    redirectToAccount();
                  }}
                  sx={{
                    px: {
                      xs: 1.5,
                      md: 2.5,
                    },
                  }}
                >
                  <ResponsiveTypography
                    variantMap={{
                      xs: 'headline10',
                      md: 'headline9',
                    }}
                  >
                    {accountBtnText}
                  </ResponsiveTypography>
                </Button>
              ) : null}

              <Box display="flex" justifyContent="flex-end" mr={-1.5}>
                <BurgerMenuBtn
                  onClick={showDrawer.toggle}
                  isActive={showDrawer.value}
                >
                  {showDrawer.value ? menuCloseText : menuBtnText}
                </BurgerMenuBtn>

                <SwipeableNav
                  links={links}
                  isOpen={showDrawer.value}
                  onClose={showDrawer.off}
                  onOpen={showDrawer.on}
                  socialNetworks={socialNetworks}
                  supportEmail={supportEmail}
                  supportPhoneNumber={supportPhoneNumber}
                />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
