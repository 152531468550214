import { BlogArticle } from '@/shared/api';

export const getArticleByCurrentLocale = (
  article: BlogArticle | null,
  locale?: string,
) => {
  if (!article) {
    return null;
  }

  const { attributes } = article;

  if (attributes.locale === locale) {
    return article;
  }

  if (attributes.localizations && attributes.localizations.data) {
    return attributes.localizations.data.find((localization) => {
      return localization.attributes.locale === locale;
    });
  }
};

export function getRandomElements<T extends object>(
  arr: T[],
  count: number,
): T[] {
  if (arr.length <= count) {
    return arr;
  }

  return [...arr].sort(() => Math.random() - 0.5).slice(0, count);
}

export const getGeneratedOneLinkUrl = (googlePlayLink = ''): string => {
  const mediaSource = {
    keys: ['utm_source'],
    defaultValue: 'website_main',
  };
  const campaign = { keys: ['utm_campaign'] };
  const adSet = { keys: ['utm_content'] };
  const ad = { keys: ['utm_term'] };
  const custom_ss_ui = { paramKey: 'af_ss_ui', defaultValue: 'true' };

  return (
    window.AF_SMART_SCRIPT.generateOneLinkURL({
      oneLinkURL: googlePlayLink,
      afParameters: {
        mediaSource,
        campaign,
        adSet,
        ad,
        afCustom: [custom_ss_ui],
      },
    })?.clickURL ?? googlePlayLink
  );
};
