import { IImage } from '@/shared/types';
import { ImageProps } from 'next/image';

type ReturnType = Pick<ImageProps, 'alt' | 'src' | 'width' | 'height'>;
export const getImageBaseProps = (image: IImage): ReturnType => {
  const imageAttrs = image?.data?.attributes;

  return {
    src: imageAttrs?.url,
    alt: imageAttrs?.alternativeText || '',
    height: imageAttrs?.height,
    width: imageAttrs?.width,
  };
};
