import { Config } from '../types';

const config: Config = {
  apiUrl: process.env.API_URL,
  currency: 'INR',
  defaultLocale: 'en',
  locales: ['en'],
  cdnUrl: process.env.CDN_PATH,
  countryCode: process.env.COUNTRY_CODE,
  faviconUrl: `${process.env.CDN_PATH}/favicons/favicon.ico`,
  logoUrl: `${process.env.CDN_PATH}/logos/full.svg`,
  logoUrlLight: `${process.env.CDN_PATH}/logos/full-white.svg`,
  logoUrlShort: `${process.env.CDN_PATH}/logos/short.svg`,
  openGraphLogoUrl: `${process.env.CDN_PATH}/logos/openGraphLogo.png`,
  title: 'TrustPaisa',
  googleTagManagerId: process.env.GOOGLE_TAG_MANAGER_ID,
  phonePrefixInternational: '+91',
  phonePrefixNational: '0',
  externalLinks: {
    whatsApp: '#',
    viber: '#',
    payEmail: '#',
    supportEmail: '#',
  },
  domain: 'trustpaisa.com',
  bankAccountHolderName: '',
  chatbotClientKey: '124bb904-dfae-45be-9e44-93581b23b596',
};

export default config;
