import { ACCESS_TOKEN_STORAGE_KEY } from '@/shared/constants';
import {
  checkFirstLoanCalculator,
  scrollToCalculatorNode,
} from '@/utils/loan-calculator';

export const isTambadanaMirror = () => {
  if (typeof window !== 'undefined') {
    const host = window.location.host;

    return (
      host === 'tambadana.my' ||
      host === 'tambadana.com' ||
      host === 'tambadana.dev.mychili.id'
    );
  }
};

export const redirectToAccount = (params = '') => {
  const url = new URL('/account' + params, window.location.origin);

  window.location.href = url.toString();
};

export const redirectTambadanaWhattsapp = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);

  if (accessToken) {
    return redirectToAccount();
  }

  window.location.href =
    'https://wa.me/601110810022?text=Hi,%20saya%20ingin%20mengetahui%20dengan%20lebih%20lanjut%20mengenai%20pinjaman%20peribadi%20Tambadana';
};

export const handleActionButtonClick = () => {
  if (checkFirstLoanCalculator()) {
    scrollToCalculatorNode();
  } else {
    redirectToAccount();
  }
};
